@import url(https://fonts.googleapis.com/css2?family=Spirax&display=swap);
body {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}



/* Navigation Bar */

body,
.scrollspy,
.scrollspy-link,
.masthead, 
.answerOptions,
.radioCustomLabel,
.question,
.my-report-col-title h3,
.report-card-header,
.report-card-text,
.report-card-text p,
.Compare-Table-Trait-OCEAN,
.leftProgress,
.rightProgress {
  margin: 0;
}

.myreports-table,
.masthead ol, 
.masthead ul,
.masthead li,
.list-short-height ol,
.my-report-traits-overview,
.trait-spectrum,
.report-card,
.comparison-table,
.myreports-table,
.desktop-display-only {
  margin: 0 auto;
}

body,
.masthead,
.masthead h3,
.myimg,
.myimghor,
.myimgvert,
.img-link,
.quote p,
.answerOptions
.answerOption,
.question,
.question h2,
.personal-report-table h3,
.my-report-row,
.reportprogressbar,
.myreportcards,
.report-card-text,
.mycardheader h5,
.Compare-Table-Trait-OCEAN,
.leftProgress,
.rightProgress {
  padding: 0;
}

.scrollspy,
.story,
.accordion-toggle,
.report-card-header {
  padding: 10px;
}

.masthead h1,
.navbartext,
.navbartext:hover,
.navbartext:focus,
.is-current,
.masthead h2,
.important-link,
.question,
.Compare-Table-Trait-OCEAN,
.notabene {
  font-weight: 900;
}

.masthead h3 {
  font-weight: 800;
}

.login-btn,
.login-btn-big,
.login-btn-big:hover,
.login-btn-big:focus,
.login-btn-big:active,
.content-btn:hover,
.content-btn:focus,
.content-btn:active,
.my-rating > div {
  background-color: #1C4689 !important;
}

.accordion-toggle,
.content-btn,
.dot,
.pill {
  background-color: #1C4689;
}

.scrollspy,
.title,
.chevron-left-icon,
.chevron-right-icon,
.grey-section,
.masthead-title h1,
.course-table-header,
.course-table-header-medium,
.course-table th,
.accordion-toggle:hover,
.accordion-toogle:focus,
.blue-section {
  background-color: #163560;
}

.overlay,
.white-section,
.card-btn-disabled,
.card-btn:hover {
  background-color: white;
}

.content-btn:hover,
.content-btn:focus,
.answerOption:hover,
.answerOption:checked,
.answerOption:focus,
.answerOption:active,
.report-card-progressbar > div,
.card-btn,
.small-dot {
  background-color: #F99746;
}

.their-rating > div,
.card-btn-signup,
.reportprogressbar > div {
  background-color: #F99746 !important;
}

.masthead h1,
.masthead h2,
.masthead h3,
.masthead h4,
.masthead h5,
.navbartext,
.title h3,
.white-section h2,
.white-section h3,
.white-section p,
.card-btn-disabled,
.content-btn-white,
.cardtext p,
.personal-report-table h3,
.menuNickname {
  color: black;
}

.scrollspy-link,
.scrollspy-link:hover,
.chevron-left-icon,
.chevron-right-icon,
.title,
.title-link,
.blue-section h2,
.blue-section p,
.home-carousel h4,
.home-carousel p,
.masthead-title h1,
.course-table-header,
.course-table-header-medium,
.course-table th,
.content-btn,
.content-btn:hover,
.content-btn:focus,
.content-btn:active,
.content-btn-grey,
.content-btn-grey:hover,
.content-btn-grey:focus,
.content-btn-grey:active,
.accordion-toggle,
.answerOption:hover,
.answerOption:nth-child(2),
.answerOption:nth-child(3),
.answerOption:nth-child(4),
.answerOption:nth-child(5),
.answerOption:nth-child(6),
.answerOption:nth-child(7),
.answerOption:nth-child(8),
.report-card-header,
.card-btn,
.white-text p {
  color: white;
}

.login-btn,
.login-btn:hover,
.login-btn:focus,
.login-btn-big,
.login-btn-big:hover,
.login-btn-big:focus,
.login-btn-big:active,
.card-btn-signup p {
  color: white !important;
}

.cardlink,
.title-link:hover,
.intlink,
.small-icon,
.report-style-result,
.three-dots-hr > p,
.cardlink,
.card-icon,
.cardlink:hover,
.card-btn:hover,
.dot-container,
q::before,
.hover-increase:hover,
.hover-increase:focus,
.hover-increase:active {
  color: #F99746;
}


.chris-img,
.cardimg,
.card-icon,
.myimg,
.myimghor,
.myimgvert,
.small-icon {
  height: auto;
}

.overlay,
.blueBoxContainer,
.mycardwrapper,
.blueBox {
  height: 100%;
}

.overlay,
.blueBoxContainer,
.is-current,
.scrollspy,
.overflow-scroll-horizontal,
.title,
.blue-section,
.masthead-title,
.masthead,
.masthead li,
img,
.notabene-p > p,
.course-table,
.course-table th,
.quote p,
.quote-p > p,
.radioCustomLabel,
.questionCount,
.question-count,
.fade-leave,
.ocean-overview,
.report-card-text,
.report-card-text p,
.content-btn {
  width: 100%;
}

.quote-p > p,
.quote p,
.notabene p,
.notabene ul,
.notabene ol,
.notabene li {
  width: 100% !important;
}

.scrollspy {
  width: 100% !important;
  margin: 0 !important;
}

.trait-spectrum,
.white-section,
.notabene p,
.accordion-width,
.personal-report-table,
.myreportoverview,
.attributecloud {
  width: 90%;
}

.title h1,
.title h2,
.title h3,
.title a {
  width: 85%;
}

.masthead h1,
.masthead h2,
.masthead h3,
.masthead h4,
.masthead h5,
.masthead p,
.masthead a,
.imgcaption,
.list-short-height ol,
.course-table-wrapper,
.my-report-traits-overview,
.two-columns,
.w-80 {
  width: 80%;
}

.card-icon {
  width: 66%;
}

.mypeople-table-width,
.myimghor,
.quote,
.responsive-box,
.my-report-row,
.three-dots-hr > p {
  width: 60%;
}

.content-btn-box {
  width: 55%;
}


.list-short-height ol li,
.reportprogressbar,
.blueBox {
  width: 50%;
}

.cardimg,
.myimg,
thead tr th:nth-child(1),
thead tr th:nth-child(3) {
  width: 40%;
}

.myimgmini {
  width: 30%;
}

.reading-pane,
.small-icon,
.answerOption,
thead tr th:nth-child(2) {
  width: 20%;
}

.title h1,
.title h2,
.title h3,
.title a,
.mypeople-table-width,
.bgvideo,
.myimg,
.myimgmini,
img,
.notabene p,
.list-short-height ol li,
.course-table-wrapper,
.responsive-box,
.small-icon,
.responsive-width,
.my-report-row,
.answerOptions,
.personal-report-table,
.myreportoverview,
.myreporth3,
.attributecloud,
.three-dots-hr > p,
.mycardwrapper,
.two-columns,
.progress-width {
  margin: auto;
}

.scrollspy-link,
.blue-section p,
.masthead p,
.masthead li,
.intlink,
.myreportbadge,
.report-content,
.report-content ul, ol, li,
.trait-intro,
.card-btn {
  font-size: 1em;
}

.pill {
  font-size: 0.6em !important;
}

.navbartext,
.blueBoxContainer {
  overflow: hidden;
}

.blue-section h2,
.blue-section p, 
.title,
.title h3,
.white-section,
.white-section h3,
.blue-section h2,
.nav-bar-link,
.masthead-title,
.masthead-title h1,
.masthead,
.masthead h2,
.masthead h3,
.masthead h4,
.masthead h5,
.masthead p,
.masthead ol, 
.masthead ul,
.masthead li,
.course-table td,
.myreportoverview,
.cardtext p,
.rightProgress > div {
  text-align: left;
}

.content-btn {
  text-align: center;
}


.intlink,
.nav-bar-link,
.navbartext:hover,
.navbartext:focus,
.hover-increase:hover,
.hover-increase:focus,
.hover-increase:active {
  text-decoration: underline;
}

.login-btn-big,
.card-btn-signup,
.content-btn,
.content-btn-grey,
.content-btn-white {
  border-radius: 35px !important;
}

.logo {
  letter-spacing: 4px;
  margin-left: 10px;
  font-weight: 400;
}

.navWrapper {
  z-index: 9999;
}

.navbartext {
  padding: 0 7.5px;
}

.navbartext:hover,
.navbartext:focus {
  color: #163560;
}

.login-btn,
.login-btn-big {
  border: #1C4689 solid 1.5px !important;
  font-weight: bold !important;
}

.login-btn {
  padding: 5px 20px !important;
  border-radius: 20px !important;
}

.login-btn-big {
  font-size: 1.5rem !important;
  padding: 5px 50px !important;
}

.content-btn,
.login-btn:hover,
.login-btn:focus,
.login-btn:active,
.login-btn-big:hover,
.login-btn-big:focus,
.login-btn-big:active {
  background-color: #163560 !important;
  border: #163560 solid 1.5px !important;
}

.login-btn:hover,
.login-btn:focus,
.login-btn:active,
.login-btn-big:hover,
.login-btn-big:focus,
.login-btn-big:active,
.content-btn:hover,
.content-btn:focus,
.content-btn:active,
.content-btn-grey:hover,
.content-btn-grey:focus,
.content-btn-white:active,
.content-btn-white:hover,
.content-btn-white:focus {
  transition: all 0.25s ease 0s;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.is-current {
  border-bottom: white 2px solid;
}

.scrollspy {
  height: 3rem;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: justify;
}

.scrollspy li {
  list-style: none;
  white-space: nowrap;
}

.chevron-left-icon,
.chevron-right-icon {
  position: fixed;
  top: 17px;
  z-index: 9998;
}

.chevron-left-icon {
  left: 15px;
}

.chevron-right-icon {
  right: 15px;
}

.dot-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 3rem;
}

.dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 35px;
  margin: auto;
  color: white;
  font-weight: 700;
  padding: 0 0 5px 0;
}

.small-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin: 40px 5px;
}


/* ======================================================================== */
/* Desktop Design */
/* ======================================================================== */

/* Home Desktop */

.overlay,
.fade-leave {
  top: 0;
  left: 0;
}

.title {
  z-index: 99999;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;  
}

.title h1 {
  padding: 0 10px 0 0;
  margin-top: 60px;
  font-size: 4.5em;
}

.title h2 {
  font-size: 2em;
  padding: 35px 0 20px 0;
}

.title h3 {
  font-size: 1.7em;
  font-weight: bold;
  margin: 10px 25px 10px 25px;
  padding-bottom: 30px;
}

.title a {
  padding: 10px 0;
  margin-bottom: 30px;
}

.title-link {
  font-style: italic;
  font-weight: bold;
}

.white-section {
  margin-left: 5%;
  font-family :  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;  
}

.white-section h2 {
  font-size: 3em;
  font-weight: bold;
  margin: 20px 5% 20px 5%;
}

.white-section h3 {
  font-size: 1.8em;
  font-style: italic;
  margin: 20px 0 20px 0;
  padding-bottom: 30px;
}



.white-section ol, li {
  margin: 0 50px 0 20px;
}

.bgvideo {
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
}

.blue-section {
  font-family :  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
}

.blue-section h2 {
  font-size: 3em;
  font-weight: bold;
  margin: 20px 10% 20px 10%;
}

.home-header2 {
  font-size: 1.5em;
  margin-bottom: 50px;
}

.home-carousel p {
  padding: 10px 0 0 0;
  margin: 10px 0 10px 0;
  font-family :  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.home-carousel h4 {
  padding: 10px 0 0 0;
  margin: 10px 0 10px 0;
  font-family :  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.carousel-caption {
  top: 0;
  bottom: auto;
}

.home-card-wrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 100px;
}

.home-card {
  background-color: #373d49 !important;
}

.chris-img {
  width: 35%;
}

.chris-img-a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;  
}

.card-btn-signup,
.card-btn-signup:hover {
  border: #F99746 2px solid !important;
}

.card-btn-signup:hover,
.card-btn-signup p:hover,
.nav-bar-link {
  color: #F99746 !important;
}

.card-btn-signup {
  margin: 20px 0 15px 0;
  display: flex;
  text-align: center;
  align-self: center;
}

.card-btn-signup:hover {
  background-color: white !important;
  transition: all 0.25s ease 0s;
}

.card-btn-signup p {
  padding: 5px;
  margin: 0 25px;
  font-size: 1.1em;
  font-weight: bold;
  line-height: normal;
  text-align: center;
}

.card-btn-disabled {
  border: #cd9557 2px solid;
}

.card-btn-disabled {
  padding: 10px 15px;
  border-radius: 20px;
}

/* Course Pages Desktop */

.reading-pane {
  display: flex;
  flex-direction: column;
  margin-top: 30%;
}

.nav-bar-link {
  font-size: 0.9em;
  font-weight: bold;
}

/* .full-width {
  margin: 0 -21.5% 0 -21.5%;
} */

.overflow-scroll-horizontal {
  overflow: auto;
}

.overflow-scroll-horizontal::-webkit-scrollbar {
  display: none;
}

.small-font {
  font-size: 0.8rem;
}

.offset-anchor:before {
  content: "";
  display: block;
  height: 200px;
  margin: -160px 0 0;
}


.masthead-title {
  z-index : 99999;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: left;  
}

.masthead-title h1 {
  font-size: 4.5em;
  padding: 50px 120px 50px 120px;
}

.masthead {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.masthead h1 {
  font-size: 4rem;
  margin: 50px auto;
  text-align: center;
}

.masthead h2 {
  font-size: 3.5em;
  margin: 40px auto;
  text-align: center;
}

.masthead h3 {
  font-size: 2.5em;
  margin: 35px auto;
  text-align: center;

}

.masthead h4 {
  font-size: 2em;
  margin: 30px auto;
  text-align: center;
  font-weight: 700;
}

.masthead h5 {
  font-size: 1.6em;
  margin: 25px auto;
  text-align: center;
  font-weight: 600;
}

.masthead p {
  color: #303030;
  line-height: 2;
  margin: 15px auto;
}



.masthead ol, ul {
  display: flex;
  flex-direction: column;
  line-height: 2;
  width: 63ch;
}

.masthead li {
  line-height: 1.8;
  float: left;
  padding: 0 10px;
}

.masthead hr {
  height: 3px;
  width: 65%;
  margin: 50px auto;
  background-color: #373d49;
  border: none;
  border-radius: 45px;
  opacity: 0.45;
}

.myreportP {
  font-size: 1.1em;
  line-height: 1.8;
  margin-top: 0;
  margin-bottom: 15px;
  padding: 0 10px 0 10px;
  font-family :  Arial, Helvetica, sans-serif;
}

.big-text {
  font-size: 2rem;
}

.myimg {
  display: flex;
  justify-content: center;
  justify-self: flex-start;
  margin-top: 20px;  
}

.myimghor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px auto;
}

.myimgvert {
  display: flex;
  justify-content: center;
  width: 45%;
  margin: 30px auto;
}

img {
  object-fit: contain;
  align-self: flex-start;
}

.img-w-30,
.img-mini {
  width: 30%;
}

.imgcaption {
  margin: 20px auto;
  text-align: center;
  line-height: 2;
  color: #989898;
  font-style: italic;
}

.imgcaption p {
  text-align: center;
  font-style: italic;
  color: #989898;
  margin: 10px auto;
  width: 65ch;
  line-height: 30px;
}

.img-caption {
  color: #989898;
}

.img-link {
  display: flex;
  align-self: center;
  justify-content: center;
  justify-self: flex-start;
  align-items: center;  
  margin: 20px;
}

.quote {
  border-left: #F99746 solid 4px;
  display: flex;
  flex-direction: column;
  justify-content: justify;
  align-items: justify;  
  text-align: justify;
  margin: 50px auto;
  padding: 25px;
}

q {
 quotes: '\201c' '\201d'; 
 font-size: 1.2rem;
 line-height: 1;
 margin: 0;
 padding: 0;
 border: black 1px solid;

}



q:after  { 
  content: no-close-quote; 
}

.quotation-mark::before { 
  content: open-quote; 
  font-size: 6em;
  font-family: 'Spirax', cursive;
}

.quote-citation {
  line-height: 1.7;
  margin: 10px auto;
  font-size: 1.6em;
  font-weight: 400;
}

.quote-source {
  text-align: right !important;
  text-transform: uppercase;
  font-weight: 600;
}

.quote p {
  line-height: 1.7;
  margin: 10px auto;
  font-size: 1.6em;
}

.notabene {
  font-size: 1.5em;
  border: #F99746 solid 4px;
  width: 70%;
  padding: 20px;
  margin: 40px auto;
  border-radius: 35px;
  text-align: center;
}

.notabene p {
  text-align: center;
  padding: 3px;
  margin: 5px;
}

.notabene i {
  display: inline;
}

.story {
  background-color: #e6e6e6;
  margin: 70px auto;
  width: 100%;
}

/* .story h3 {
  margin: 20px 0 20px 0;
} */

.story ul,
.story ol {
  width: 95%;
}

.story p,
.story li {
  width: clamp(50ch, 90%, 65ch);
}

.course-table-header {
  font-size: 1.3em;
  text-align: center;
}

.course-table-header-medium {
  font-size: 1.2em;
  justify-self: flex-start;
}

.border-top-left-radius {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.border-top-right-radius {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.tables-emotions-body tr, td {
  font-size: 14px;
}

.course-tables-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.course-table-wrapper {
  border: #555555 solid 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  text-align: center;
}

.course-table {
  margin: 0 0 20px 0;
}

.course-table th {
  font-size: 1.8em;
  padding: 10px 20px 10px 20px;
  border: #555555 solid 1px;
}

.course-table td {
  float: left;
  padding: 10px 20px 0 20px;
}

.intlink {
  font-weight: bold;
}

.important-link {
  text-align: left;
  transition: all 0.25s ease;
  margin: auto;
  width: 100%;
}

.important-link:active,
.important-link:hover,
.important-link:focus {
  font-size: 1.3rem;
}

.blue-text {
  color: #193A6F;
}

.hover-cursor {
  cursor: pointer;
}

.content-btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
}

.content-btn {
  border: #193A6F 2px solid !important;
  font-weight: 800 !important;
  font-size: 1.2em !important;
  padding: 10px 40px;
}

.content-btn:hover,
.content-btn:focus,
.content-btn:active {
  border: #2B599D 2px solid !important;
}

.hover-increase:hover,
.hover-increase:focus,
.hover-increase:active {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.25s ease 0s;
}


.content-btn-grey {
  background-color: #A5ACBA !important;
  border: #A5ACBA 2px solid  !important;
  font-weight: 800 !important;
  font-size: 1em !important;
  padding: 10px 15px;
}

.content-btn-grey:hover,
.content-btn-grey:focus {
  background-color: #5A606D !important;
  border: #5A606D 2px solid !important;
}

.content-btn-white {
  background-color: white !important;
  border: white 2px solid  !important;
  font-weight: 900 !important;
  font-size: 1.2em !important;
  padding: 10px 25px;
  text-align: center;
}

.chris-img-lg {
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.separator {
  margin: 15px 0 15px 0;
}

.separator-small {
  margin: 5px 0 0 0;
}

.separator-large {
  margin: 50px;
}

.accordion-width {
  margin: 30px auto;
}

.accordion-toggle {
  cursor: pointer;
  border: none;
  border-radius: 35px;
}

.accordion-toggle:hover,
.accordion-toogle:focus {
  cursor: pointer;
  transition: all 0.25s ease;
}

.green-thumbs-up {
  color: #5cb85c;
}

.red-error {
  color: red;
}

.responsive-width {
  width: 45%;
}

@media screen and (min-width: 1000px) {
  .masthead p {
    width: 65ch;
  }
}

@media screen and (max-width: 1000px) {
  .desktop-display-only {
    display: none;
  }
}

/* ======================================================================== */
/* Mobile Design */
/* ======================================================================== */

/* Home Mobile */

@media screen and (max-width: 600px) {
  .imgcaption p,
  .myimg,
  .myimghor,
  .story,
  .content-btn {
    width: 100%;
  }

  .story h3,
  .story ul,
  .story ol,
  .story p,
  .story li {
    width: 95%;
  }

  .chris-img,

  
  .notabene,
  .notabene p,
  .responsive-box,
  .responsive-width,
  .my-report-row,
  .mycardwrapper,
  .imgcaption {
    width: 90%;
  }

  .quote,
  .masthead p,
  .content-btn-box,
  .masthead h2,
  .masthead h3,
  .masthead h4,
  .masthead h5,
  .mypeople-table-width,
  .progress-width {
    width: 80%;
  }

  .myimgmini {
    width: 60%;
  }
  
  .title {
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;  
  }
  
  .title h1 {
    margin-top: 40px;
    font-size: 2.5em;
  }
  
  .title h2 {
    font-size: 1.5em;
  }
  
  .title h3 {
    font-size: 1.7em;
  }
  
  .white-section h2 {
    font-size: 2.4em;
    font-weight: bold;
    margin: 20px 5%;
  }
  
  .white-section h3 {
    font-size: 1.4em;
    font-style: italic;
    margin: 20px 0;
    padding-bottom: 30px;
  }
  
  .blue-section h2 {
    font-size: 2.3em;
    font-weight: bold;
    margin: 20px 10%;
  }

  .blue-section h4 {
    font-size: 1.1em;
  }
  
  .home-header2 {
    font-size: 1.5em;
    margin-bottom: 50px;
  }
  
  .home-carousel p,
  .home-carousel h4 {
    padding: 10px 0 0 0;
    margin: 10px 0;
  }
  
  .carousel-caption {
    top: 0;
    bottom: auto;
  }
  
  .card-btn-signup p {
    margin: 0 15px;
    font-size: 1.1em;
  }
  

  /* Course Pages Mobile */
  
  .left-reading-pane,
  .desktop-display-only {
    display: none;
  }

  .scrollspy {
    -webkit-overflow-scrolling: touch;
  }

  .masthead {
    display: flex;
    flex-direction: column;
    align-items: left;  
  }
  
  .masthead h1 {
    font-size: 3em;
  }

  .masthead-title,
  .blue-section p {
    font-size: 0.7em;
  }
  
  .masthead h2 {
    font-size: 2.4em;
    margin: 50px auto;
  }
  
  .masthead h3 {
    font-size: 2em;
    margin: 40px auto;
  }
  
  .masthead h4 {
    font-size: 1.7em;
    margin: 40px auto;
  }
  
  .masthead p {
    line-height: 2;
    margin: 15px auto;
  }
  
  .masthead ol, 
  .masthead ul {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    width: 85%;
  }
  
  .masthead li {
    line-height: 1.8;
    padding: 0 20px;
  }
  
  .myreportP {
    font-size: 1.1em;
    line-height: 1.8;
    margin-bottom: 15px;
    padding: 0 10px 0 10px;
    font-family :  Arial, Helvetica, sans-serif;
  }
  
  .myimg {
    display: flex;
    align-self: center;
    justify-content: center;
    justify-self: flex-start;
    text-align: center;
    margin: 40px auto;
    margin-bottom: 10px;
  }
  
  .myimghor {
    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;
    margin: 40px auto;
    margin-bottom: 10px;
  }
  
  .myimgvert {
    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;
    width: 70%;
    margin: 40px auto;
  }
  
  img {
    object-fit: contain;
    align-self: flex-start;
  }


  .imgcaption p {
    text-align: center;
  }
  
  .quote {
    border-left: #cd9557 solid 4px;
    display: flex;
    flex-direction: column;
    margin: 40px auto;
    padding: 15px 0 20px 5px;
  }
  
  .quote p {
    line-height: 1.6;
    padding: 5px 0 5px 10px;
    margin: 5px auto;
    font-size: 1.5em;
  }
  
  .notabene {
    font-size: 1.1em;
    margin: 25px auto;
    padding: 15px;
    border-radius: 35px;
  }
  
  .notabene p {
    text-align: center;
    padding: 3px;
    margin: 5px;
  }

  .story {
    background-color: #e6e6e6;
    margin: 70px auto;
    padding: 10px;
  }
  
  .story h3 {
    margin: 20px auto;
  }
  
  .course-table-header {
    font-size: 1em;
  }

  .course-table-header-medium {
    font-size: 0.6em;
    justify-self: flex-start;
  }

  .course-table-body td {
    font-size: 0.9em
  }
  
  .tables-emotions-body tr, td {
    font-size: 7.5px;
  }
  
  .course-tables-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .course-table-wrapper {
    border: #555555 solid 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    text-align: center;
  }
  
  .course-table th {
    font-size: 1.8em;
    padding: 10px 20px 10px 20px;
    background-color: #373d49;
    border: #555555 solid 1px;
  }
  
  .course-table td {
    float: left;
    padding: 10px 20px 0 20px;
  }

  .trait-spectrum {
    width: 98%;
  }
  
  .content-btn {
    border: #2B599D 2px solid;
    font-weight: bold;
    font-size: 1.2rem;
    display: inline-block;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 10px 15px 10px 15px;
    border-radius: 25px;
  }

  .questionCount {
    font-size: 1.2rem;
    padding-left: 15px;
  }

  .answerOptions {
    list-style-type: none;
  }

  .question h2 {
    margin: 20px 0 20px 0;
    font-size: 1.3rem;
  }

  .leftProgress > div {
    font-size: 0.5rem !important;
  }

  .rightProgress > div {
    font-size: 0.5rem !important;
  }

  .report-card {
    width: 39%;
  }

  .report-card-progressbar {
    align-self: center;
    width: 22%;
    margin: 0 auto;
  }

  .my-report-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50%;
  }

  .three-dots-hr {
    font-size: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    letter-spacing: 13px;
  }

}

/* Result Component */
.result {
  padding: 1.5rem 2.5rem;
}

/* Quiz Component */
.progress-bar-small-height {
  height: 5px !important;
}

.answerOptions {
  list-style-type: none;
}

.answerOption {
	display: inline-table;
  max-width: 18em;
  min-width: 18em;
  border-radius: 12px;
}

.answerOption:hover {
  transition: all 0.25s ease 0s;
}

.answerOption:nth-child(1) {
  background-color: #C2D0FF;
  color: #2a2a2a;
  margin-bottom: 10px;
}

.answerOption:nth-child(2) {
  background-color: #96A5D3;
  margin-bottom: 10px;
}

.answerOption:nth-child(3) {
  background-color: #6C7BA7;
  margin-bottom: 10px;
}

.answerOption:nth-child(4) {
  background-color: #43547D;
  margin-bottom: 10px;
}

.answerOption:nth-child(5) {
  background-color: #1B3055;
  margin-bottom: 10px;
}

.answerOption:nth-child(6) {
  background-color: #06193b;
  margin-bottom: 10px;
}

.answerOption:nth-child(7) {
  background-color: #00163f;
  margin-bottom: 10px;
}
.answerOption:nth-child(8) {
  background-color: #000000;
  margin-bottom: 10px;
}


.radioCustomButton {
  position: absolute;
  width: 1px;
  opacity: 0;
}

.radioCustomButton,
.radioCustomLabel {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.radioCustomLabel {
  position: relative;
  padding: 0.8rem 2rem 0.8rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: bold;
}

.radioCustomButton ~ .radioCustomLabel:before {
  position: absolute;
  top: 21px;
  left: 4px;
  width: 10px;
  height: 10px;
  content: '';
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  transition: all 0.3s;
}

.radioCustomButton:checked ~ .radioCustomLabel:before {
  content: '';
  background: white no-repeat;
  background-size: 10px;
}

/* QuestionCount Component */
.questionCount {
  font-size: 1.6rem;
  padding-left: 20px;
}

.question-count {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Question Component */


.question h2 {
  margin: 20px 0 0 0;
}

.personal-report-table h3 {
  margin: 50px;
}

/* Animation */
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
  position: absolute;
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.left-pb-arrow {
  float: left;
  z-index: 99;
  margin-right: -14px;
  margin-top: -15px;
}

.right-pb-arrow {
  float: right;
  z-index: 99;
  margin-right: -16px;
  margin-top: -32px;
}


/* Personal Report Desktop */

.myreportoverview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 50px;
}



.my-report-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.myreporth3 {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.report-btn-a {
  font-size: 2rem;
}

.report-btn-a:hover {
  color: #193A6F;
}


.report-card {
  width: 33%;
}

.reportprogressbar {
  font-weight: bold;
  margin: 0 5px;
  border: solid 1px #c9bbac;
  align-self: center;
}

.report-card-progressbar {
  font-weight: bold;
  align-self: center;
  width: 30%;
  height: 25px !important;
  margin: 0 5px;
  border: solid 1px #c9bbac;
}

.report-card-progressbar > div {
  height: 25px !important;
}

.ocean-overview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
}

.report-card-header {
  font-weight: bold;
  background: #1B3055 !important;
  text-align: center;
}

.report-card-text p {
  font-size: 0.8em;
  padding: 0 0 0 3px;
  line-height: 20px;
}

.attributecloud {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-content {
  text-align: justify;
}

.report-content ul, ol, li {
  text-align: justify;
}

#root > amplify-authenticator{
  text-align: center;
}

.trait-intro {
  text-align: justify;
  padding-left: 20px;
  padding-top: 20px;
}

.blueBoxContainer {
  position: absolute;
}

.three-dots-hr {
  font-size: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  letter-spacing: 13px;
}

.three-dots-hr > p {
  text-align: center;
  margin-top: 0;
  margin-bottom: 40px;
  font-size: larger;
}

/* */

.compareP {
  margin-left: 30%;
  max-width: 70ch;
}

.mycardwrapper {
  border: none !important;
  border-radius: 40px !important;
}

.my-card-deck {
  flex: initial;
  flex-wrap: wrap;
}

.cardlink:hover {
  text-decoration: none;
}

.card-btn {
  border: #cd9557 2px solid;
  padding: 10px 15px 10px 15px;
  border-radius: 25px;
}

.card-btn:hover {
  border: #cd9557 2px solid;
  transition: all 0.25s ease 0s;
}

.two-columns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.cardtitle {
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 10px 5px 10px 5px;
  margin: 20px 0 20px 0;
  font-size: 0.8em;
  text-align: center;
}

.cardtext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.cardtext p {
  font-size: 0.85em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 25px;
  margin: 0 0 12px 0;
  padding: 0 25px 0 10px;
}

.cardtext ul, li {
  font-size: 0.9em;
  line-height: 25px;
  margin: 0 0 0 10px;
}

.blueBox {
  background-color: rgba(0,46,102,.8);
  -webkit-transform: skewX(-8deg) translateX(-40%);
          transform: skewX(-8deg) translateX(-40%);
  outline: 1px solid transparent;
  position: absolute;
  z-index: 10;
}

.container {
  margin-top: 2em;
}

.comparison-table,
.personal-report-table {
  table-layout: fixed;
}

.myreports-table,
.comparison-table,
.course-table {
  width: 90% !important;
}

.course-table {
  margin: 30px auto;
}

.leftProgress {
  display: block !important;
  float: right !important;
  height: 30px !important;
  width: 99% ;
}

.leftProgress > div {
  height: 30px !important;
  background-color: #CED5E4 !important;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  font-size: 0.8rem;
  color: black !important;
  text-align: right;
  padding-right: 5px;
}

.progress {
  background-color: transparent !important;
}

.rightProgress {
  display: block !important;
  float: left !important;
  height: 30px !important;
  width: 99% ;
}

.rightProgress > div {
  height: 30px !important;
  background-color: #163560 !important;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  font-size: 0.8rem;
  padding-left: 5px;
}

@media screen and (min-width: 601px) {
  .progress-width {
    width: 50%;
  }
}

.my-rating {
  height: 2em !important;
  font-weight: 900;
}

.my-rating > div,
.their-rating > div {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.their-rating {
  height: 2em !important;
  font-weight: 900;
}

.text-black {
  color: black !important;

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgba(0,46,102,.8);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

